* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#root{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

// This applies from 0px to 600px
body {
  background: #fff;
}
  
header{
  position: top;
  top: 0;
  width: 100%;
}

main{
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

h3{
  font-size: 2rem;
}

footer{
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  margin-top: auto;
  padding: 2rem;
  p{
    color: #5b5b5b;
    font-size: 0.8rem;
  }
  .link{
    color: rgb(46, 95, 255);
  }
}

.flex-center-col{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.banner-text-container{
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  padding: 2.5rem;
  gap: 1.3rem;
}

.banner-main-text{
  font-size: 3rem;
  font-weight: 600;
  --animate-duration: 1.7s;
}

.banner-sub-text{
  font-size: 1.7rem;
  font-weight: 300;
  --animate-duration: 1.2s;
}
.banner-btn{
  --animate-duration: 0.5s;
}

/* Header / Navigation */
.logo{
  height: 30px;
  width: auto;
}

.navbar{
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 4rem;
  padding: 1rem 3rem;
  top: 0;
  position: sticky;
  background-color: #fff;
  z-index: 99;
  /* background-image: url('./images/webfix-studio-logo-918x250.png');
  background-size: 6rem;
  background-position: center;
  background-repeat: no-repeat; */
}
.list-btn{
  color: black;
  text-decoration: none;
}

.logo-container{
  flex-grow: 1;
}
  
// This applies from 600px onwards
@media (min-width: 600px) {
  .banner-text-container{
    width: 55%;
  }
}




