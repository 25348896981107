.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #215488;
    color: #fff;
    height: auto;
    padding: 2.3rem 1rem;
    p{
        padding: 1.3rem;
        color: whitesmoke;
        text-align: center;
    }
}

.wordpressLogo{
    height: auto;
    width: 200px;
}